import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleReactLightbox from "simple-react-lightbox" // Import Simple React Lightbox
import { SRLWrapper } from "simple-react-lightbox" // Import SRLWrapper
import StackGrid, { transitions } from "react-stack-grid"
import sizeMe from "react-sizeme"

const { scaleDown } = transitions

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const {
      size: { width },
    } = this.props

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>
          <SimpleReactLightbox>
            <SRLWrapper>
              <StackGrid
                // columnWidth={328}
                duration={1500}
                columnWidth={width <= 768 ? "100%" : "33.33%"}
                monitorImagesLoaded={true}
              >
                {post.frontmatter.images.map(node => (
                  <a href={node.childImageSharp.fluid.src} data-attribute="SRL">
                    <img src={node.childImageSharp.fluid.src} />
                  </a>
                ))}
              </StackGrid>
            </SRLWrapper>
          </SimpleReactLightbox>
          <footer className="post-content-footer">
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
      default byline. */}
          </footer>
        </article>
      </Layout>
    )
  }
}

export default sizeMe()(BlogPostTemplate)

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        images {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
